import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FormBs from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router";

import type { AssignmentResponse } from "Src/api/Dto";
import type { SearchedBook } from "Src/components/Common/SearchBooks";
import type { DropdownOptions } from "Src/interfaces/Interfaces";

import { SearchBooks } from "Src/components/Common/SearchBooks";

import { updateAssignmentRequest } from "Src/squawk/ActionsAssignmentRequest";
import { useSquawkApp } from "Src/squawk/StoreApp";
import { useSquawkAssignmentRequest } from "Src/squawk/StoreAssignmentRequest";

import { dateTodayOrLater, transformPricePropertiesResponse } from "../../../../../utils/Utils";
import FormCommon from "../../Common/FormCommon";

interface Props {
  assignments: AssignmentResponse[] | undefined;
  loading?: boolean;
  searchedBook?: SearchedBook | undefined;
  onSelectBook: (selectedBook: SearchedBook | undefined) => void;
}

export function Form(props: Props) {
  const { assignments, loading, searchedBook, onSelectBook } = props;
  const { isbn: isbnUrl } = useParams<{ isbn: string }>();
  const { signedVendors } = useSquawkApp();
  const isbn = searchedBook?.isbn || isbnUrl;

  const { vendorBrId } = useSquawkAssignmentRequest();
  const [vendorOptions, setVendorOptions] = useState<DropdownOptions[]>([]);
  const editMode = assignments?.some((vendor) => vendor.vendorBrId === vendorBrId) ?? false;
  const activeVendor = signedVendors.find((vendor) => vendor.vendorBrId === vendorBrId);

  useEffect(() => {
    if (assignments?.length === 0 && (isbn === undefined || isbn === "")) {
      setVendorOptions([]);

      return;
    }
    const vOptions = getVendorOptions();
    setVendorOptions(vOptions);
    const id = vendorBrId === "" ? vOptions?.[0].value : vendorBrId;
    const selectedVendor = signedVendors.find((vendor) => vendor.vendorBrId === id);
    if (!selectedVendor) {
      return;
    }

    if (isbn && !editMode) {
      setDefaultDates(id);
    }
  }, [assignments, editMode, isbn]);

  const getLatestAssignment = (assignments: AssignmentResponse[]) => {
    return assignments.reduce((mostRecentAssignment, currentAssignment) => {
      const mostRecentTimestamp = new Date(mostRecentAssignment.updated);
      const currentTimestamp = new Date(currentAssignment.updated);

      return currentTimestamp > mostRecentTimestamp ? currentAssignment : mostRecentAssignment;
    }, assignments[0]);
  };

  /**
   * Set dates from latest assignment as defaults
   */
  const setDefaultDates = (vendorBrId: string | undefined) => {
    if (!assignments || assignments.length === 0) {
      return;
    }

    const { validFrom, validTo } = getLatestAssignment(assignments);

    updateAssignmentRequest({
      validFrom: dateTodayOrLater(validFrom),
      validTo: dateTodayOrLater(validTo ?? ""),
      vendorBrId
    });
  };

  const getVendorOptions = (): DropdownOptions[] => {
    if (editMode) {
      return [{ key: activeVendor?.vendorBrId ?? "", value: activeVendor?.vendorBrId ?? "", text: activeVendor?.name ?? "" }];
    }
    let filteredVendorList = signedVendors;
    // Only allow vendors who have no assignments in dropdown
    if (assignments?.length && assignments.length > 0) {
      filteredVendorList = signedVendors.filter((vendor) => !assignments.some((assignment) => assignment.vendorBrId === vendor.vendorBrId));
    }

    if (filteredVendorList.length === 0) {
      return [{ key: "0", value: "0", text: "Du har tilldelat alla återförsäljare" }];
    }

    // Convert to array the dropdown component can render
    return filteredVendorList.map((vendor) => ({ key: vendor.vendorBrId, value: vendor.vendorBrId, text: vendor.name }));
  };

  return (
    <Container className="ms-0">
      <Row>
        <Col lg="4" md="6">
          <FormBs>
            {!isbnUrl && (
              <FormBs.Group className="mb-3">
                <FormBs.Label>
                  <strong>Välj isbn</strong>
                </FormBs.Label>
                <SearchBooks searchedBook={searchedBook} onSelectBook={onSelectBook} />
              </FormBs.Group>
            )}
            <FormBs.Group className="mb-3">
              <FormBs.Label>
                <strong>Välj återförsäljare</strong>
              </FormBs.Label>
              {!editMode && (
                <FormBs.Select
                  disabled={loading || vendorOptions.length === 0}
                  value={vendorBrId === "" ? vendorOptions?.[0]?.value : vendorBrId}
                  onChange={(e) => {
                    const selectedVendor = signedVendors.find((vendor) => vendor.vendorBrId === e.target.value);
                    if (!selectedVendor) {
                      return;
                    }
                    updateAssignmentRequest({
                      validFrom: "",
                      validTo: "",
                      vendorBrId: e.target.value,
                      territoryAssignments: [],
                      priceProperties: transformPricePropertiesResponse(selectedVendor.priceProperties)
                    });
                  }}
                >
                  {vendorOptions.map((option) => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </FormBs.Select>
              )}
              {editMode && <FormBs.Control disabled value={activeVendor?.name} />}
            </FormBs.Group>
            <FormCommon editMode={editMode} keepVendorBrIdOrIsbnOnCancel="isbn" vendor={activeVendor} />
          </FormBs>
        </Col>
      </Row>
    </Container>
  );
}
