import dayjs from "dayjs";

import type { PricePropertyItemRequest, PricePropertyRequest, PricePropertyResponse } from "Src/api/Dto";

export const removeDateClock = (date: string) => {
  if (date && date !== "") {
    return dayjs(date).format("YYYY-MM-DD");
  }

  return "Datum saknas";
};

/**
 * Transforms price properties of type PricePropertyResponse[] to PricePropertyRequest[]
 *
 * @param vendorPriceProperties -
 * Contains possible price properties for a given vendor. Guaranteed to contain of price properties if the vendor has it.
 * @param assignmentPriceProperties -
 * Contains price properties with values for a given vendor and assignment.
 * Used when editing existing assignments. Possible that it's empty for a given assignment even if the vendor is supposed to use price properties.
 * @returns price properties array with values if they exist
 */
export const transformPricePropertiesResponse = (
  vendorPriceProperties: PricePropertyResponse[],
  assignmentPriceProperties?: PricePropertyResponse[]
) => {
  const pricePropertiesRequest = vendorPriceProperties?.map<PricePropertyRequest>((priceProperty) => {
    // Contains price property values for a given assignment
    const assignmentPricePropertyItems = assignmentPriceProperties?.find(
      (assignmentPricePropery) => assignmentPricePropery.name === priceProperty.name
    )?.pricePropertyItems;

    return {
      name: priceProperty.name,
      pricePropertyItems: priceProperty?.pricePropertyItems?.map<PricePropertyItemRequest>((pricePropertyItem) => {
        const aPricePropertyItemValue = assignmentPricePropertyItems?.find(
          (assignmentPricePropertyItem) => assignmentPricePropertyItem.name === pricePropertyItem.name
        )?.value;

        return {
          name: pricePropertyItem.name,
          value: aPricePropertyItemValue ?? "" // Use price property value from a given assignment if it exists
        };
      })
    };
  });

  return pricePropertiesRequest;
};

export const formatDate = (date: Date | [Date, Date] | null) => {
  const formattedDate = (date as Date)?.toLocaleDateString("sv-SE");

  return formattedDate;
};

export const getSessionStorage = <T>(name: string) => {
  const item = sessionStorage.getItem(name);
  if (!item) {
    return;
  }

  return JSON.parse(item) as T;
};

export const downloadAjax = (url: string, isbn: string, apiKey: string, brId?: string) => {
  return new Promise((resolve, reject) => {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Ocp-Apim-Subscription-Key", apiKey);
    if (brId) {
      xhr.setRequestHeader("brId", brId);
    }
    xhr.send();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status > 210) {
          reject(xhr);
        } else if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: "application/zip" });
          const a = document.createElement("a");
          document.body.appendChild(a);
          const windowUrl = window.URL.createObjectURL(blob);
          a.href = windowUrl;
          a.download = `${isbn}.zip`;
          a.click();
          window.URL.revokeObjectURL(windowUrl);
          resolve("Finished");
        }
      }
    };
  });
};

export const dateTodayOrLater = (date: string) => {
  const dateWithoutTime = removeDateClock(date);
  const todayDateWithoutTime = removeDateClock(new Date().toISOString());
  if (dateWithoutTime >= todayDateWithoutTime && dateWithoutTime !== "Datum saknas") {
    return dateWithoutTime;
  }

  return "";
};
