/* eslint-disable */
/* eslint-disable prettier/prettier */
export enum AgreementType {
    Standard = 1,
    Existing = 2,
    Reporting = 3,
}
export enum EventType {
    BookCreated = 1,
    BookUpdated = 2,
    BookDeleted = 3,
    MetadataUpdated = 4,
    ContentUploaded = 5,
    AssignmentCreated = 6,
    AssignmentUpdated = 7,
    AssignmentActivated = 8,
    AssignmentEnded = 9,
    SignedAgreementCreated = 10,
    SignedAgreementUpdated = 11,
    SignedAgreementDeleted = 12,
    DeleteContent = 13,
}
export enum ItemValueType {
    Text = 0,
    Numeric = 1,
    Decimal = 2,
    Secret = 3,
    Currency = 4,
    Boolean = 5,
    Date = 6,
}
export enum UserType {
    Publisher = 1,
    Vendor = 2,
    Admin = 3,
    System = 4,
}
